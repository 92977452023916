import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('slider', function (conf = {}) {
    return {
      instance: null,
      ready: false,
      enabled: null,
      transition: false,
      transitionToNext: false,
      transitionToPrev: false,
      activeIndex: 0,
      isActive(index) {
        return this.activeIndex === index
      },
      async load() {
        const root = this.$root
        const slider = this.$refs['slider'] || root
        const [Swiper, modules] = await Promise.all([
          import(/* webpackMode: "lazy-once" */'swiper').then((mod) => mod.default),
          import(/* webpackMode: "lazy-once" */'swiper/modules').then((mod) => [
            mod.Navigation,
            mod.EffectCards,
          ]),
          import(/* webpackMode: "lazy-once" */'swiper/css'),
        ])
        const [next, prev] = [
          this.$refs['next'],
          this.$refs['prev']
        ]

        this.instance = new Swiper(slider, {
          observeSlideChildren: true,
          modules,
          ...conf,
          ...((next || prev) && {
            navigation: {
              nextEl: next,
              prevEl: prev,
              disabledClass: 'opacity-50',
              ...conf.navigation,
            },
          }),
          on: {
            afterInit: (instance) => {
              this.ready = true
              this.enabled = instance.enabled
            },
            enable: () => {
              this.enabled = true
            },
            disable: () => {
              this.enabled = false
            },
            slidesUpdated: () => {
              // FIX
              if (!this.enabled) slider.querySelector('.swiper-wrapper').removeAttribute('style')
            },
            slideChangeTransitionStart: () => {
              this.transition = true
            },
            slideChangeTransitionEnd: () => {
              this.transition = false
            },
            slideNextTransitionStart: () => {
              this.transitionToNext = true
            },
            slideNextTransitionEnd: () => {
              this.transitionToNext = false
            },
            slidePrevTransitionStart: () => {
              this.transitionToPrev = true
            },
            slidePrevTransitionEnd: () => {
              this.transitionToPrev = false
            },
            slideChange: () => {
              this.activeIndex = this.instance.activeIndex
            }
          },
        })

        // bubble some events
        ;['slideChange'].map(ev => {
          this.instance.on(ev, () => {
            root.dispatchEvent(new CustomEvent(kebabize(ev), {
              bubbles: true,
              composed: true,
              cancelable: true,
            }))
          })
        })
      },
    }
  })
})()


const kebabize = (str) => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase())